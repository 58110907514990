import { render, staticRenderFns } from "./oldLpFooter.vue?vue&type=template&id=136eda53&scoped=true&"
import script from "./oldLpFooter.vue?vue&type=script&lang=js&"
export * from "./oldLpFooter.vue?vue&type=script&lang=js&"
import style0 from "./oldLpFooter.vue?vue&type=style&index=0&id=136eda53&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "136eda53",
  null
  
)

export default component.exports