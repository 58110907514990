<template>
  <div class="lp-footer" :style="paddingBottom">
    <div class="footer container">
      <div class="columns">
        <div class="column" :class="`is-${item.col}-desktop`"
             v-for="(item, idx) in footer" :key="'footer-'+idx">

          <div class="lp-title">{{ item.title }}</div>

          <div class="lp-cont" v-for="(content, cidx) in item.contents"
               :key="'content-'+cidx">
            <a :href="content.link" v-if="isALink(content.link)" @click="clickLink(content, false)">{{ content.title }}</a>
            <a v-else-if="content.link"
               @click="clickLink(content, true)">{{ content.title }}</a>
            <span v-else>{{ content.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "lp-footer",

    methods: {
      clickLink(content, isPushLink) {
        this.setGa(`푸터 (${content.title})`, '클릭', content.title);
        if(isPushLink) {
          this.$router.push(content.link);
        }

      },
      isALink(link) {
        let type = ['tel', 'mailto', 'http', 'https'];
        let head = link.split(':')[0];
        if(type.indexOf(head)>-1)
          return true;
        else
          return false;
      }
    },

    computed: {
      paddingBottom() {
        if((window).innerWidth < 601) {
          if(this.$route.path.search('store/detail') > -1) {
            return 'padding-bottom: 50px;'
          }
        }
        return '';
      }
    },

    data() {
      return {
        footer: [
          {
            col: 3,
            title: '서비스 이용',
            contents: [
              /*{
                title: '비밀번호 찾기',
                link: ''
              },*/
              {
                title: 'FAQ',
                link: '/cs?menu=faq'
              },
              {
                title: '새소식',
                link: '/cs?menu=notice'
              },
              {
                title: '1:1 문의',
                link: 'mailto:support@launchpack.co.kr'
              },
            ]
          },
          {
            col: 3,
            title: '서비스 정책',
            contents: [
              {
                title: '서비스 이용약관',
                link: '/term'
              },
              {
                title: '개인정보 보호정책',
                link: '/limit'
              },
              /*{
                title: '호스팅 이용약관',
                link: ''
              },
              {
                title: '디지털 콘텐츠 이용약관',
                link: ''
              }*/
            ]
          },
          {
            col: 2,
            title: '제휴',
            contents: [
              {
                title: '제휴 제안',
                link: 'mailto:support@launchpack.co.kr'
              }
            ]
          },
          {
            col: 4,
            title: '기업정보',
            contents: [
              { title: '주식회사 런치팩', link: '' },
              { title: '광주본사 : 광주광역시 동구 서남로 14 410호', link: '' },
              { title: '서울지사 : 서울특별시 서초구 강남대로51길 1 15F', link: '' },
              { title: '이메일 : support@launchpack.co.kr', link: 'mailto:support@launchpack.co.kr' },
              { title: '전화 : 1544-9247', link: 'tel:1544-9247' },
              { title: '사업자등록번호 : 694-87-01163', link: '' },
              { title: '통신판매업신고 : 제 2018-광주동구-0192 호', link: '' },
              { title: '대표자 : 염민지 | 개인정보책임자 : 이남호', link: '' },
            ]
          }
        ]
      }
    }
  }
</script>

<style scoped>
  .lp-footer, .footer {
    /*background-color: rgb(51, 51, 51);*/
    background-color: #292c32;
    color: white;
    clear:both;


  }

  .lp-footer{
    width:100%;
  }

  .footer {
    padding-bottom: 3rem;
  }



  .lp-title {
    color: #a0a0a0;
    margin-bottom: 1.3rem;
  }

  .lp-cont {
    color: white;
    font-size: 0.9rem;
    margin-bottom: 8px;
  }

  a {
    color: white;
    cursor: pointer;
  }

  a:hover {
    color: #cccccc;
  }
  @media(max-width:768px){
    .lp-footer{
      border-top:none;
    }
  }

  .column {
    padding: 0;
  }

</style>
